$(document).ready(function() {

    function resizeAraignee() {
        relierTitreLien = document.getElementById('relier-toile');

        if (relierTitreLien) {

            relierTitreLienWidth = relierTitreLien.offsetWidth;
            araignee = document.getElementById('araignee');
            relierToile = document.getElementById('relier_toile');
            relierToileWidth = relierToile.offsetWidth;
            araigneeWidth = (relierTitreLienWidth - relierToileWidth) / 2;
            araigneePosition = araigneeWidth + relierToileWidth - (araigneeWidth / 2);

            araignee.style.cssText = "width:" + araigneeWidth + "px;left:" + araigneePosition + "px;";
        }
    }

    resizeAraignee();

    function resizeSVG() {
        svgTitle = document.getElementById('svg_title');

        if(svgTitle){
            svgTitleHeight = document.getElementById('svg_title').offsetHeight + 'px';
            svgContainer = document.getElementById('relier-svg');
            svgContainer.style.height=svgTitleHeight;
        }
    }

    resizeSVG();



        //SVG
        pelote = document.getElementById('pelote');
        bobine = document.getElementById('bobine');
        mic = document.getElementById('mic');
        mandorle = document.getElementById('mandorle');
        cable = document.getElementById('cable');
        trait = document.getElementById('trait');
        path = document.getElementById('mandorle_path');

        $('#pelote').on('load',function(){
            fil = document.getElementById('fil');
            turning = pelote.getElementsByClassName('cls-3');
            fil.classList.toggle('paused');
            for (var i = 0; i < turning.length; i++) {
                turning[i].classList.toggle('paused');
            }
            $("#fil").bind("animationiteration", function() {
                for (var i = 0; i < turning.length; i++) {
                    turning[i].classList.toggle('reverse');
                }
            });
        });

        $('#bobine').on('load',function(){
            console.log('ok');
            film = document.getElementById('film');
            trous = document.getElementById('trous');
            film.classList.toggle('paused');
            trous.classList.toggle('paused');
            $("#film").bind("animationiteration", function() {
                trous.classList.toggle('reverse');
            });
        });

        $('#mic').on('load',function(){
            cable.classList.toggle('paused');
            son = document.getElementsByClassName('son');
            for (var i = 0; i < son.length; i++) {
                son[i].classList.toggle('blinking');
                var path = document.querySelector(son[i]);
                var length = path.getTotalLength();
                console.log(length);
            }
        });

        $('#mandorle').on('load',function() {
            trait.classList.toggle('paused');
            path.classList.toggle('paused');
        });






    function handle(delta) {
        var time = 1000;
        var distance = 300;

        $('html, body').stop().animate({
            scrollTop: $(window).scrollTop() - (distance * delta)
        }, time );
    }

    handle(1200);

    //lightbox carousel


    // carousel
    $('.carousel-item:first-of-type').addClass('active');
    $('.carousel').carousel({
        interval: 10000
    });

    let screenRatio = window.innerWidth / window.innerHeight;

    let lightboxImages = document.getElementsByClassName('lightbox-image');
    let lightboxImagesLength = lightboxImages.length;
    for (let i=0; i< lightboxImagesLength; i++){
        let lightboxImage = lightboxImages[i];
        let lightboxImageWidth = lightboxImage.naturalWidth;
        let lightboxImageHeight = lightboxImage.naturalHeight;
        let lightboxImageRatio = lightboxImageWidth / lightboxImageHeight;

        if(lightboxImageRatio < 1 || screenRatio > lightboxImageRatio){
            lightboxImageNewHeight = lightboxImage.style.height = '100%';
            lightboxImageNewWidth = lightboxImage.style.width = 'auto';
        }

    }

    var index = 'lightbox-0';

    $('#carousel_oeuvres').on('slide.bs.carousel', function (event) {
        index = 'lightbox-' + event.to;
    });

    $('.img-carousel').click(function () {
        $(".lightbox[data='" + index + "']").css("display", "flex");
    });

    $(".lightbox").click(function (event) {
        $(".lightbox").fadeOut("fast");
    });









    //calcul hauteur ecran - navbar
    navBar = document.getElementById('relier-navbar');
    breaksImg = document.getElementsByClassName('break-img');
    navBarHeight = navBar.offsetHeight;
    oneScreenHeight = window.innerHeight - navBarHeight;

    for (var i = 0; i < breaksImg.length; i++) {
        breaksImg[i].style.height= oneScreenHeight + 'px';
    }

    // effets home
    var scrollY = 0;
    mainTitle = document.getElementById('relier_title');
    burger = document.getElementById('burger');
    navContainer = document.querySelector('.nav-container');

    burger.addEventListener('click', function () {
        navContainer.classList.toggle('open');
        navBar.style.background = '#efefef';
    });



    window.addEventListener('scroll', function() {
        scrollY = window.scrollY;
        if (scrollY > (30)){
            navBar.style.background = '#efefef';
        }

        else {
            navBar.style.background = 'transparent';
        }

        if (scrollY > 5){
            if (mainTitle !== null) {
                mainTitle.classList.add('closing');
            }
        }

        else {
            if (mainTitle !== null) {
                mainTitle.classList.remove('closing');
            }
        }
    });


    //responsive svg

    window.addEventListener('resize', function () {
        resizeAraignee();
        resizeSVG();
    });






});